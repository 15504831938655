// 
// _table.scss
// 

@media only screen and (max-width: 600px) {
  .table th {
    font-size: x-small;
  }
  .card-body .card-body{
    padding: 1.25rem 0;
  }
}

.table {
  th {
      font-weight: $font-weight-medium;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}